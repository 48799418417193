import React, { Component, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import store from './store'
import { sessionId } from './localStorage';
import Login from './views/Login/Login'
import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context'
import { access } from 'fs'
import { User, WebStorageStateStore } from 'oidc-client-ts'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Logout = React.lazy(() => import('./views/Logout/Logout'))


interface IProps {
  children: JSX.Element
}
function RequireAuth(p: IProps) {
  let location = useLocation()

  let auth = store.getState().isAuthenticated

  if (!auth) {
    return (
      <Navigate to='/login' state={{ from: location }} replace />
    )
  }

  return p.children
}

function App() {
  return (
    <OauthWrapper>
      <AppRoutes />
    </OauthWrapper>
  )
}



// app to load and authenticate routes
function AppRoutes() {
  const auth = useAuth();
  const appState = store.getState();

  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      console.log('token expiring')
      auth.signinSilent();
      // if (confirm("You're about to be signed out due to inactivity. Press continue to stay signed in.")) {
      // }
    })
  }, [auth.events, auth.signinSilent]);

  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
      console.log('signing in silently on startup')
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);


  switch (auth.activeNavigator) {
    case "signinRedirect":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  // if (auth.isLoading) {
  //   return <div>Loading...</div>;
  // }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated && appState.isAuthenticated) {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route path="*" element={
              <DefaultLayout />
            } />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }

  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;

}

export default App



function OauthWrapper(p: IProps) {

  if (localStorage) {
    let token = localStorage.getItem(sessionId.token);
    let roles = localStorage.getItem(sessionId.roles);

    if (roles !== null && roles !== "") {
      let rolesArray = JSON.parse(roles);
      store.dispatch({
        type: 'set',
        roles: rolesArray
      })
      console.log('OAuthWrapper: load roles to local storage')
    }

    if (token != null) {
      store.dispatch({
        type: 'set',
        token: token,
        isAuthenticated: true
      })
      console.log('OAuthWrapper: load token to local storage')
    }
  }

  const onSigninCallback = (user: void | User) => {
    console.log(user);
    let rolesArray: any = [];
    let accesstoken: string = '';
    let isPayrollOffice: boolean = false;

    if (user?.access_token) {
      accesstoken = user?.access_token;
      if (localStorage) {
        localStorage.setItem(sessionId.token, accesstoken)
      }
    }

    console.log('token: ' + accesstoken)

    try {
      let tokenDataString: string = atob(accesstoken.split('.')[1]);
      console.log(tokenDataString)
      var tokenObj: { [id: string]: any } = JSON.parse(tokenDataString)

      // check for roles
      rolesArray = tokenObj['role']
      if (Array.isArray(tokenObj['role']) == false) {
        rolesArray = [tokenObj['role']];
      }

      // check for payroll office
      isPayrollOffice = tokenObj['payrolloffice'] ? JSON.parse(tokenObj['payrolloffice']) : false;

      if (localStorage) {
        localStorage.setItem(sessionId.roles, JSON.stringify(rolesArray));
        localStorage.setItem(sessionId.payrollOffice, JSON.stringify(isPayrollOffice));
      }
    }
    catch {
      console.log("ERROR: parse roles in token")
    }


    store.dispatch({
      type: 'set',
      roles: rolesArray,
      token: accesstoken,
      isPayrollOffice: isPayrollOffice,
      isAuthenticated: accesstoken != '' ? true : false
    })



    // check for param **code** in location
    let locationSearch = window.location.search;
    if (locationSearch.indexOf("code") != -1) {
      window.history.replaceState
        ({},
          '',
          window.location.origin + window.location.hash)
    }
  }


  return (
    <AuthProvider
      client_id='js'
      scope='openid role offline_access profile.api guest.api blob.api joblisting.api notification.api IdentityServerApi'
      automaticSilentRenew={true}
      authority={process.env.REACT_APP_IDENTITY_URL as string}
      redirect_uri={window.location.origin}
      userStore={new WebStorageStateStore({ store: window.localStorage })}
      onSigninCallback={onSigninCallback}
    >
      {p.children}
    </AuthProvider>
  )
}
